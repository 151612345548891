<template>
    <div
        class="w-full dF fC f1 px-4 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
    >
        <FilterDrawer />
        <EmptyApp
            v-if="!isAddOnsAvailable"
            :text="'You don\'t have any add ons inventory'"
            :img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
        />

        <AddonManagement
            v-else="isAddOnsAvailable"
            :key="$store.state.inventory.newTime"
        />
    </div>
</template>

<script>
import EmptyApp from "bh-mod/components/common/EmptyApp";
import AddonManagement from "@/components/inventory/AddonManagement";
import FilterDrawer from "@/components/inventory/FilterDrawer";

export default {
    components: {
        EmptyApp,
        AddonManagement,
        FilterDrawer,
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        tags() {
            return this.$store.state.inventory.tags;
        },
		condoAddons() {
            return this.$store.state.inventory.condoAddons
		},
		isAddOnsAvailable(){
            return this.condoAddons && Object.values(this.condoAddons).some(a => a?.length)
		}
    },
    methods: { },
    created() {
		this.$store.dispatch('FETCH_REALTORS')
        this.$store.commit("UPDATE_CRUMBS", "unitAddOns");
        const tags = Object.values(this.tags);
        if (
            (tags.length && tags.filter((x) => x && x.name).length == 0) ||
            !tags.length
        ) {
            this.$store.commit("LOAD", true);
            let type = "models";
            if (this.instance.productType === "lowrise") {
                type = "lots";
            }
            this.$api
                .get(`/tags/:instance?type=${type}`)
                .then(({ data }) => {
                    this.$store.commit("DO_TAGS", data);
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        }
    },
};
</script>

<style scoped>
.display-card {
    border-radius: 3px;
    border-color: rgba(63, 63, 63, 0.15);
    -webkit-box-shadow: 0 1px 3px rgb(63 63 68 / 15%);
    border: none;
    background-color: #fff;
}
.grid-display {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    display: grid;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-5 {
    gap: 1.25rem;
}
</style>
<style lang="scss">
.tab-height .ant-tabs-content {
    height: 100%;
}
.tab-height .empty-app-height {
    height: 100%;
}
</style>
